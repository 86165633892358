<template>
  <div>
    <v-form v-model="isFormValid" lazy-validation ref="form">
      <v-row dense>
        <v-col cols="10">
          <v-text-field
            v-model="formData.newPassword"
            dense
            filled
            persistent-hint
            label="Nueva contraseña"
            color="secondary"
            :rules="[
              formRules.required('La nueva contraseña no puede estar vacía'),
            ]"
            :type="inputType"
            :hint="inputHint"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-btn
            block
            color="secondary"
            height="53"
            :loading="loading"
            :disabled="!isFormValid"
            @click="saveNewPassword"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import formRules from "../../plugins/rules";
import serverRequestMixin from "@/mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  data() {
    return {
      formRules,
      loading: false,
      error: "",
      isFormValid: false,
      inputType: "text",
      inputHint: "",
      formData: {
        newPassword: "",
      },
    };
  },

  methods: {
    async saveNewPassword() {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;

      this.loading = true;

      try {
        await this.putRequest("/users/user/password", this.formData);
        this.inputType = "password";
        this.inputHint = "La contraseña se ha modificado correctamente";
      } catch (error) {
        error.status < 500
          ? (this.error = error.data.message)
          : console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
