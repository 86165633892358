<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-toolbar dense dark flat tile color="secondary">
        <v-toolbar-title>Ajustes de la cuenta</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-subheader class="px-0 mx-0">Contraseña</v-subheader>
            <p>
              Modifica la contraseña que utilizarás para tu siguiente acceso en
              la aplicación.
            </p>
            <password-form></password-form>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import userPasswordForm from "../users/userPassword.form.vue";

export default {
  components: {
    "password-form": userPasswordForm,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
